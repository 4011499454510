import template from './tickets.html';
import style from './tickets.less';
import seatsTemplate from './seats/seats.html';

export default angular.module('eventix.shop.tickets', [ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.tickets', {
            url: '/tickets?eventId',
            views: {
                'shop': {
                    controller: 'TicketsController as vm',
                    templateUrl: template
                }
            },
            params: {
                isCamping: false
            },
            resolve: /*@ngInject*/ {}
        });
    })
    .controller('TicketsController', function(UIMessages, shop, order, collapses, $state,
        $scope, $q, $http, $translate, Locales, Currencies, Ticket, $interval, $sce,
        $analytics, FB, $timeout, store, $filter, $location, SeatsIO, ShopAnalytics, tickets, $uibModal) {
        var vm = this;
        vm.seatSelection = {
            templateUrl: seatsTemplate,
            open: false
        };
        vm.state = $state.current.name;
        vm.order = order;
        vm.messages = UIMessages;
        vm.locales = Locales;
        vm.currencies = Currencies;
        vm.shop = shop;
        vm.tickets = order.tickets;
        vm.justTickets = tickets;
        vm.events = order.events;
        vm.products = order.products;
        vm.collapses = collapses;
        vm.eventFilter = $state.params.eventId;
        vm.isCamping = false;
        if ($state.params.isCamping)
            vm.isCamping = $state.params.isCamping;
        vm.atWeekendTickets = false;
        vm.reservationsToDisplay = [];
        vm.subtotalWithoutFee = 0;

        let queryParams = $location.search();
        if (queryParams.coupon)
            vm.order.applyCoupon(queryParams.coupon);

        /** Iterate over events/tickets and check availability */
        vm.availableTickets = order.recalculateAvailableTickets();
        var checkAvailable = $interval(() => vm.availableTickets = order.recalculateAvailableTickets(), 10000);
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');
        $scope.$on('destroy', () => $interval.cancel(checkAvailable));

        if(vm.fourStepWizard && !vm.eventFilter)
            $state.go('eventix.shop.events', { shopId: vm.shop.guid });

        if(vm.fourStepWizard && vm.eventFilter)
            ShopAnalytics.impression(vm.tickets[vm.eventFilter]);
        else if(!vm.fourStepWizard)
            ShopAnalytics.impression();

        /**
         * Close seat selection dialog
         */
        vm.closeSeatSelection = function() {
            vm.seatSelection.open = false;
        };

        vm.openSeatSelection = function(ticket, eventId) {
            SeatsIO.open(ticket, eventId).then(() => vm.seatSelection.open = true);
        };

        /** Goto next page in shop if user has selected 1+ ticket */
        vm.next = function() {
            if (_.flatten(_.values(order.reservations)).length < 1)
                UIMessages.push('common.shop.order.empty');
            else
                $state.go('eventix.shop.personalDetails', { shopId: shop.guid });
        };

        vm.isLocker   = isLocker;
        function isLocker(name){
            return (name).toLowerCase().includes('pendel');
        }

        vm.reserve = function(ticketGuid, eventId){
            // if (vm.reachedMaxReservations()) {
            //     UIMessages.push("A max of 5 tickets per order is allowed");
            //     return;
            // }

            vm.order.reserveTicket(ticketGuid, eventId).then(() => {
                vm.calculateSubtotalWithoutFee()
            });
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.addToCart(ticket);
        };

        vm.release = function(ticketGuid, eventId){
            vm.order.releaseTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.removeFromCart(ticket);
            vm.calculateSubtotalWithoutFee()
        };

        vm.scrollToSingleDay = () => {
            vm.atWeekendTickets = false;
            document.getElementById('single-day-tickets').scrollIntoView();
        };

        vm.scrollToWeekend = () => {
            vm.atWeekendTickets = true;
            document.getElementById('weekend-tickets').scrollIntoView();
        };

        vm.reachedMaxReservations = () => {
            let totalReserved = 0;
            for (const ticketGuid in vm.order.reserveQueue)
                totalReserved += vm.order.reserveQueue[ticketGuid].length;

            for (const ticketGuid in vm.order.reservations)
                totalReserved += vm.order.reservations[ticketGuid].length;

            return totalReserved >= 5;
        };

        vm.calculateSubtotalWithoutFee = () => {
            let subtotalExclFees = 0;

            Object.entries(vm.order.reservations).forEach(([guid, value]) => {
                if (vm.order.reservations[guid].length > 0) {
                    subtotalExclFees += vm.order.reservations[guid].length * vm.order.reservations[guid][0].ticket.min_price;
                }
            });

            vm.subtotalWithoutFee = subtotalExclFees;
        };

        vm.openCo2Modal = () => {
            $uibModal.open({
                template: `
                    <div class="co2-modal">
                        <div class="co2-modal__title">
                            <h2>What happens with this donation?</h2>
                        </div>
                        <div class="co2-modal__body">
                            These generic amounts have been calculated via <a href="https://www.atmosfair.de/en/" target="_blank">Atmosfair</a>, a non-profit organization that invests in CO₂ compensation through various renewable energy projects in more than 15 countries worldwide. Your donation will be used to start and extend such projects in the global south, such as rural electrification with solar power and mini-grids in Madagascar, small biogas plants for farmers in Nepal or efficient cookstoves in Rwanda. Read more about their projects <a href="https://www.atmosfair.de/en/climate-protection-projects/" target="_blank">here</a>, and find out more about why we compensate CO₂ via this organization <a href="https://hetkabinetfestival.nl/sustainable-travel" target="_blank">here</a>.
                        </div>
                    </div>
                `
            });
        };
    }).name;
